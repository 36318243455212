import * as React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "../Button";
import Languages from "../Languages";
import GoOnDemandIcon from "../../svg/DabaStoreIcon";
import { Trans } from 'gatsby-plugin-react-i18next';
import config from '../../config';

const Header = () => {
  let [menu, setMenu] = React.useState(false);
  let [item, setItem] = React.useState(0);
  const openRestaurantApp = React.useCallback(() => window.open(config.GATSBY_RESTAURANT_APP_URL, "_blank"), []);

  return (
    <nav
      className="bg-white shadow fixed w-full"
      style={{
        zIndex: 9999,
      }}
    >
      <div className="px-0 md:mx-6 sm:mx-10 flex flex-col sm:flex-row justify-between">
        <div className="flex justify-between h-16 w-full">
          <div className="items-center logo">
            <div className="flex h-full">
              <div className="-ml-2 mr-2 flex items-center md:hidden">
                {/* <!-- Mobile menu button --> */}
                <button
                  type="button"
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                  onClick={() => {
                    setMenu(!menu);
                  }}
                >
                  <span className="sr-only">Open main menu</span>
                  {/* <!-- Icon when menu is closed. --> */}

                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  {/* <!-- Icon when menu is open. --> */}
                  <svg
                    className="hidden h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex items-center h-full w-full">
                <AnchorLink
                  href="/"
                >
                  <GoOnDemandIcon className="w-full" />
                </AnchorLink>

                {/*
                in case we needed to add the small logo for the small screens ,
                  1) add this classes line to the previous logo :  hidden lg:block h-8 w-auto
                  2) uncomment this line below
                  <GoOnDemandSmall className="block lg:hidden h-8 w-auto" />
                 */}
              </div>
            </div>
          </div>
          <div className="hidden md:flex md:space-x-8 justify-center">
            <button
              onClick={() => {
                setItem(0);
              }}
              className={
                item === 0
                  ? "border-primary text-gray-900 inline-flex items-center px-1 pt-1 border-b-4 outline-none "
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 outline-none  "
              }
            >
              <AnchorLink
                offset={() => 64}
                className="h-full flex items-center xl:px-2 md:px-1 md:text-sm xl:text-lg"
                href="#solutions"
              >
                <Trans i18nKey="header.menu.solutions" />
              </AnchorLink>
            </button>
            <button
              onClick={() => {
                setItem(1);
              }}
              className={
                item === 1
                  ? "border-primary text-gray-900 inline-flex items-center px-1 pt-1 border-b-4 outline-none "
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 outline-none "
              }
            >
              <AnchorLink
                offset={() => 64}
                className="h-full flex items-center xl:px-2 md:px-1 md:text-sm xl:text-lg"
                href="#features"
              >
                <Trans i18nKey="header.menu.features" />
              </AnchorLink>
            </button>
            <button
              onClick={() => {
                setItem(2);
              }}
              className={
                item === 2
                  ? "border-primary  text-gray-900 inline-flex items-center px-1 pt-1 border-b-4 outline-none "
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 outline-none "
              }
            >
              <AnchorLink
                offset={() => 64}
                className="h-full flex items-center xl:px-2 md:px-1 md:text-sm xl:text-lg"
                href="#pricing"
              >
                <Trans i18nKey="header.menu.pricing" />
              </AnchorLink>
            </button>
            {/*<button
                onClick={() => {
                  setItem(3);
                }}
                className={
                  item === 3
                      ? "border-primary  text-gray-900 inline-flex items-center px-1 pt-1 border-b-4 outline-none "
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 outline-none "
                }
            >
              <AnchorLink
                  offset={() => 64}
                  className="h-full flex items-center xl:px-2 md:px-1 md:text-sm xl:text-lg"
                  href="#company"
              >
                <Trans i18nKey="header.menu.company" />
              </AnchorLink>
            </button>*/}
            <button
              onClick={() => {
                setItem(4);
              }}
              className={
                item === 4
                  ? "border-primary  text-gray-900 inline-flex items-center px-1 pt-1 border-b-4 outline-none "
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 outline-none "
              }
            >
              <AnchorLink
                offset={() => 64}
                className="h-full flex items-center xl:px-2 md:px-1 md:text-sm xl:text-lg"
                href="#faq"
              >
                <Trans i18nKey="header.menu.faq" />
              </AnchorLink>
            </button>
            <button
                onClick={() => {
                  setItem(5);
                }}
                className={
                  item === 5
                      ? "border-primary  text-gray-900 inline-flex items-center px-1 pt-1 border-b-4 outline-none "
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 outline-none "
                }
            >
              <AnchorLink
                  offset={() => 64}
                  className="h-full flex items-center xl:px-2 md:px-1 md:text-sm xl:text-lg"
                  href="#contact"
              >
                <Trans i18nKey="header.menu.contact" />
              </AnchorLink>
            </button>
          </div>
          <div className="flex items-center justify-end">
          <div className="flex-shrink-0">
              <AnchorLink
                className="mr-2 lg:mr-4" 
                href="#free-trial"
              >
                <Button 
                  className="line-h-17 lg:py-2 py-2 md:px-2 text-sm bg-primary text-white border-primary border-solid border-2" 
                  size="none"
                >
                  <Trans i18nKey="createAccount" />
                </Button>
              </AnchorLink>
            </div>
            <div className="flex-shrink-0 hide-for-mobile">
              <Button 
                className="line-h-17 lg:py-2 py-2 md:px-2 text-sm bg-white text-primary border-primary border-solid border-2" 
                size="none"
                onClick={openRestaurantApp}
              >
                <Trans i18nKey="login" />
              </Button>
            </div>
          </div>
        </div>
        <Languages className="hide-for-mobile" />
      </div>

      {/* <!-- Mobile menu, show/hide based on menu state. --> */}
      {menu && (
        <div className="md:hidden" id="mobile-menu">
          <div className="pt-2 space-y-1">
            <button
              onClick={() => {
                setMenu(false);
              }}
              className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
            >
              <AnchorLink offset={() => 64} className="px-4" href="#solutions">
                <Trans i18nKey="header.menu.solutions" />
              </AnchorLink>
            </button>
            <button
              onClick={() => {
                setMenu(false);
              }}
              className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
            >
              <AnchorLink offset={() => 64} className="px-4" href="#features">
                <Trans i18nKey="header.menu.features" />
              </AnchorLink>
            </button>
            {/*<button
              onClick={() => {
                setMenu(false);
              }}
              className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
            >
              <AnchorLink offset={() => 64} className="px-4" href="#company">
                <Trans i18nKey="header.menu.company" />
              </AnchorLink>
            </button>*/}
            <button
              onClick={() => {
                setMenu(false);
              }}
              className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
            >
              <AnchorLink offset={() => 64} className="px-4" href="#pricing">
                <Trans i18nKey="header.menu.pricing" />
              </AnchorLink>
            </button>
            <button
              onClick={() => {
                setMenu(false);
              }}
              className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
            >
              <AnchorLink offset={() => 64} className="px-4" href="#faq">
                <Trans i18nKey="header.menu.faq" />
              </AnchorLink>
            </button>
            <button
                onClick={() => {
                  setMenu(false);
                }}
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
            >
              <AnchorLink offset={() => 64} className="px-4" href="#contact">
                <Trans i18nKey="header.menu.contact" />
              </AnchorLink>
            </button>
            <button
                onClick={openRestaurantApp}
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6 only-for-mobile"
            >
              <AnchorLink offset={() => 64} className="px-4" href="#contact">
                <Trans i18nKey="login" />
              </AnchorLink>
            </button>
          </div>
          <Languages className="menu pl-6 pb-3" menu />
        </div>
      )}
    </nav>
  );
};

export default Header;
