import * as React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';

// custom components
import SocialMedia from './SocialMedia';

const Footer = () => (
  <footer className='md:mx-24 mx-2 sm:mx-10 py-16 px-3 mt-10 mb-8 text-gray-800'>
    <div className='flex flex-col sm:flex-row'>
      <div className='flex-1'>
        <h2 className='text-lg font-semibold'>
          <Trans i18nKey='footer.mainTitle' />
        </h2>
        <p className='mt-5'>
          <Trans i18nKey='footer.subtitle' />
        </p>
        <br />
        <p>
          <span>
            <Trans i18nKey='footer.phone' />
            <a href="tel:+212661137928">
              06.61.13.79.28
            </a>
          </span>
          <br />
          <Trans i18nKey='footer.contactAddress' />
        </p>
      </div>

      <div className='flex-1 flex flex-col sm:items-end items-start sm:mt-0 mt-12'>
        <div className='w-3/5'>
          <h2 className='text-lg font-semibold'>
            <Trans i18nKey='footer.menu.title' />
          </h2>
          <ul className='mt-4 leading-loose'>
            <li>DabaStore</li>
            <li>
              <a href='./cgv/' target='_blank' rel='noopener noreferrer'>
                <Trans i18nKey='footer.menu.generalCondition' />
              </a>
            </li>
            <li>
              <a href='./privacy/' target='_blank' rel='noopener noreferrer'>
                <Trans i18nKey='footer.menu.privacyPolicy' />
              </a>
            </li>
          </ul>
          <SocialMedia />
        </div>
      </div>
    </div>
    <div className='footer-credit'>
      <Trans i18nKey='footer.rights' values={{ year: new Date().getFullYear() }} />
    </div>
  </footer>
);

export default Footer;
